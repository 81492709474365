<template>
  <v-dialog
    v-model="dialogAdicionarHistorico"
    @click:outside="$emit('update:dialogAdicionarHistorico', false)"
    @keydown.esc="$emit('update:dialogAdicionarHistorico', false)"
    width="500"
    scrollable
  >
    <v-card>
      <v-card-title class="accent text-subtitle-1 font-weight-bold px-4">
        Adicionar Tempo
        <v-spacer></v-spacer>
        <v-icon @click="$emit('update:dialogAdicionarHistorico', false)">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="px-6 py-8">
        <v-row>
          <!-- Data -->
          <v-col cols="12">
            <DataField label="Data" :data_sync.sync="data" obrigatorio />
          </v-col>

          <v-col cols="12" md="6">
            <TimeField
              :hora_sync.sync="hora_inicio"
              :label="'Hora Início:'"
              :entrada="entrada"
              :saida_intervalo="saida_intervalo"
              :volta_intervalo="volta_intervalo"
              :saida="saida"
              :disabled="!data"
            />
          </v-col>

          <!-- Hora de Postagem -->
          <v-col cols="12" md="6">
            <TimeField
              :hora_sync.sync="hora_fim"
              :label="'Hora Fim:'"
              :entrada="entrada"
              :saida_intervalo="saida_intervalo"
              :volta_intervalo="volta_intervalo"
              :saida="saida"
              :disabled="!data"
            />
          </v-col>

          <!-- Nota -->
          <v-col cols="12">
            <v-textarea v-model="note" label="Nota" dense rows="2"></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-btn outlined @click="$emit('update:dialogEditarHistorico', false)">
          Voltar
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn color="secondary" :disabled="disableAddTime" @click="adicionarTempo">
          adicionar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { postAtividadeAdicionarTempo } from "@/api/colaborador/atividades_colaborador.js";
import { mapState } from "vuex";

export default {
  name: "AdicionarHistoricoTempo",

  components: {
    DataField: () => import("@/components/fields/DataField.vue"),
    TimeField: () => import("@/components/fields/TimeField.vue"),
  },

  props: {
    dialogAdicionarHistorico: {
      type: Boolean,
    },
    atividade_id: {
      type: [Number, String],
    },
  },

  data() {
    return {
      loading: true,
      data: null,
      hora_inicio: "00:00",
      hora_fim: "00:00",
      note: "",
      entrada: "",
      saida_intervalo: "",
      volta_intervalo: "",
      saida: "",
    };
  },

  computed: {
    ...mapState("Usuario", {
      colaborador_id: (state) => state.usuario.colaborador_id,
      jornada_trabalho: (state) => state.usuario.jornada_trabalho,
    }),

    disableAddTime() {
      let result = false;

      if (!this.data || !this.hora_inicio || !this.hora_fim) {
        result = true;
      }

      return result;
    },
  },

  watch: {
    data(newData) {
      let parts = newData.split("-");
      let year = parseInt(parts[0], 10);
      let month = parseInt(parts[1], 10) - 1;
      let day = parseInt(parts[2], 10);

      let localDate = new Date(year, month, day, 0, 0, 0);

      if (this.data) {
        this.dia_semana = new Date(localDate)
          .toLocaleDateString("pt-BR", {
            weekday: "long",
          })
          .replace("-feira", "")
          .toUpperCase();

        if (this.jornada_trabalho.length > 0) {
          let jornada = this.jornada_trabalho.find(
            (jornada) => jornada.dia_semana == this.dia_semana
          );
          if (jornada) {
            this.entrada = new Date(newData + " " + jornada.entrada);
            this.saida_intervalo = new Date(newData + " " + jornada.saida_intervalo);
            this.volta_intervalo = new Date(newData + " " + jornada.volta_intervalo);
            this.saida = new Date(newData + " " + jornada.saida);
          }
        }
      }
    },
  },

  methods: {
    adicionarTempo() {
      this.loading = true;
      let tempo = {};
      tempo.atividade_id = this.atividade_id;
      tempo.colaborador_id = this.colaborador_id;
      tempo.data = this.data;
      tempo.hora_inicio = this.hora_inicio;
      tempo.hora_fim = this.hora_fim;
      tempo.note = this.note;

      postAtividadeAdicionarTempo(tempo)
        .then((response) => {
          if (response.status === 201) {
            if (response.data.result == "success") {
              this.$emit("update:dialogAdicionarHistorico", false);
              this.$emit("fetch-historico");
            }
            if (response.data.result == "failed") {
              this.$toast.error(response.data.message);
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },

  async mounted() {},
};
</script>

<style></style>
